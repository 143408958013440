import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Icon1 from "../../img/Icon1.png";
import Icon2 from "../../img/Icon2.png";
import Icon3 from "../../img/Icon3.png";
import Icon4 from "../../img/Icon4.png";
import IconVote from "../../img/flyer.png";
import BottomNav from "../../layouts/BottomNav";
import useReactRouter from "use-react-router";
import { ABSENCE, APPROVE, HISTORY_ABSENCE, HOME_VOTE } from "../../routes/app";
import { useLazyQuery } from "@apollo/client";
import { getStaffLogin } from "../../helper";
import Schema from "./Apollo";
import TotalDay from "./Component/TotalDay";
import { TiThMenuOutline } from "react-icons/ti";
import ModalListManager from "./Component/ModalListVote";
import background from "../../img/background.avif";
import backItem from "../../img/backContent.jpg";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdDragIndicator } from "react-icons/md";

export default function Menu() {
  const { history } = useReactRouter();
  const user = getStaffLogin();
  const position = user?.position;
  const [data, setData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const [dataToShow, setDataToShow] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const handleShow = (data, title) => {
    setDataToShow(data);
    setModalTitle(title);
    setShowModal(true);
  };

  const [queryVacationDay, { data: result, loading }] = useLazyQuery(
    Schema.QUERY_VACATION_DAY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    queryVacationDay({
      variables: {
        where: {},
      },
    });
  }, []);

  useEffect(() => {
    setData(result?.vacationDay?.data);
  }, [result]);

  const [queryEvaluationCenter, { data: dataCenter }] = useLazyQuery(
    Schema.QUERY_EVALUATION_ALL,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [queryEvaluationBranch, { data: dataBranch }] = useLazyQuery(
    Schema.QUERY_EVALUATION_ALL,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [queryEvaluationUserManager, { data: dataUserManager }] = useLazyQuery(
    Schema.QUERY_EVALUATION_ALL,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [queryEvaluationUserCenterDay, { data: dataUserCenterDay }] =
    useLazyQuery(Schema.QUERY_EVALUATION_ALL, {
      fetchPolicy: "cache-and-network",
    });

  const [queryEvaluationUserCallCenter, { data: dataUserCallCenter }] =
    useLazyQuery(Schema.QUERY_EVALUATION_ALL, {
      fetchPolicy: "cache-and-network",
    });

  const [queryEvaluationUserCenterNight, { data: dataUserCenterNight }] =
    useLazyQuery(Schema.QUERY_EVALUATION_ALL, {
      fetchPolicy: "cache-and-network",
    });

  const [queryEvaluationUserCenterMain, { data: dataUserCenterMain }] =
    useLazyQuery(Schema.QUERY_EVALUATION_ALL, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    queryEvaluationCenter({
      variables: {
        where: {
          type: "CENTER",
        },
      },
    });

    queryEvaluationBranch({
      variables: {
        where: {
          type: "BRANCH",
        },
      },
    });

    queryEvaluationUserManager({
      variables: {
        where: {
          type: "MANAGER",
        },
      },
    });

    queryEvaluationUserCenterDay({
      variables: {
        where: {
          type: "CENTER_IS_DAY",
        },
      },
    });

    queryEvaluationUserCallCenter({
      variables: {
        where: {
          type: "CALL_CENTER",
        },
      },
    });

    queryEvaluationUserCenterNight({
      variables: {
        where: {
          type: "CENTER_IS_NIGHT",
        },
      },
    });

    queryEvaluationUserCenterMain({
      variables: {
        where: {
          type: "CALL_CENTER_CENTER",
        },
      },
    });
  }, [
    queryEvaluationCenter,
    queryEvaluationBranch,
    queryEvaluationUserManager,
    queryEvaluationUserCenterDay,
    queryEvaluationUserCallCenter,
    queryEvaluationUserCenterNight,
    queryEvaluationUserCenterMain,
  ]);

  const userManager = dataUserManager?.evaluationAll?.userEvaluation; //ຫົວຫນ້າແລະຮອງພະແນກ
  const centerAll = dataCenter?.evaluationAll?.centerEvaluation; //ສູນຕ່າງແຂວງ
  const branchAll = dataBranch?.evaluationAll?.branchEvaluation; //ສາຂາທັງຫມົດ
  const userCallCenter = dataUserCallCenter?.evaluationAll?.userEvaluation; //ສຳນັກງານ
  const userCenterDay = dataUserCenterDay?.evaluationAll?.userEvaluation; //ກະເຊົ້າ
  const userCenterNight = dataUserCenterNight?.evaluationAll?.userEvaluation; //ກະແລງ
  const userCenterMain = dataUserCenterMain?.evaluationAll?.userEvaluation; //ສູນໂຊກໃຫຍ່

  const [queryEvaluation, { data: DataEvaluation }] = useLazyQuery(
    Schema.QUERY_EVALUATION,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    queryEvaluation({
      variables: {
        where: {},
      },
    });
  }, []);

  return (
    <div
      id="appCapsule"
      style={{
        backgroundColor: "#400a36",
        marginBottom: 0,
        paddingBottom: 0,
      }}
    >
      <div className="appHeader text-light border-0 text-right">
        <div style={{ flex: 1 }} className="text-left text-center">
          ເມນູ
        </div>
      </div>
      <ModalListManager
        show={showModal}
        setShow={handleClose}
        dataToShow={dataToShow}
        modalTitle={modalTitle}
      />
      <div
        className="body-content body-content-lg"
        // style={{ backgroundSize: "cover", backgroundImage: `url(${backItem})` }}
      >
        <div className="container" style={{ height: "100vh" }}>
          <Card
            className="p-2 mb-3 d-flex"
            style={{
              marginTop: "-140px",
              height: "180px",
              // border: "2px solid #5B1E21",
              // backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="text-center">
                <h3>ວັນລາພັກທີ່ຍັງເຫຼືອ</h3>
              </div>
            <Row className="mt-2">
              <Col className="col-6">
                {data?.slice(0, 2).map((item, index) => (
                  <div key={index}>
                    <TotalDay item={item} />
                  </div>
                ))}
              </Col>
              <Col className="col-6">
                {data?.slice(2, 3).map((item, index) => (
                  <div key={index}>
                    <TotalDay item={item} />
                  </div>
                ))}
                {user?.gender === "FEMALE" && (
                  <>
                    {data?.slice(3, 4).map((item, index) => (
                      <div key={index}>
                        <TotalDay item={item} />
                      </div>
                    ))}
                  </>
                )}
              </Col>
            </Row>
            {/* <div
              id="carouselExampleControlsNoTouching"
              className="carousel slide"
              data-bs-touch="true"
            >
              <div class="carousel-inner">
                <div className="carousel-item active">
                  <div className="d-block">
                    {" "}
                    <Row>
                      <h4 className="d-flex justify-content-center m-0 text-white mb-4">
                        ຫົວໜ້າ ແລະ ຮອງພະແນກ
                      </h4>
                    </Row>{" "}
                    {userManager?.slice(0, 3).map((item, index) => (
                      <Row
                        key={item?.user_id}
                        className="align-items-center"
                        onClick={() =>
                          handleShow(userManager, "ອັນດັບ ຫົວໜ້າ ແລະ ຮອງພະແນກ")
                        }
                      >
                        <Col className="col-3 text-center">
                          <h4 className="text-white">{index + 1}</h4>
                        </Col>
                        <Col className="col-5 text-start">
                          <h4 className="text-white">
                            <FaRegEyeSlash />
                          </h4>
                        </Col>
                        <Col className="col-4 text-start">
                          <h4 className="text-white">{item?.point} ຄະເເນນ</h4>
                        </Col>
                      </Row>
                    ))}
                    <Row></Row>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="d-block">
                    {" "}
                    <Row>
                      <h4 className="d-flex justify-content-center m-0 text-white mb-4">
                        ສຳນັກງານ
                      </h4>
                    </Row>{" "}
                    {userCallCenter?.slice(0, 3).map((item, index) => (
                      <Row
                        key={item?.user_id}
                        className="align-items-center "
                        onClick={() => handleShow(userCallCenter, "ສຳນັກງານ")}
                      >
                        <Col className="col-3 text-center">
                          <h4 className="text-white">{index + 1}</h4>
                        </Col>
                        <Col className="col-5 text-start">
                          <h4 className="text-white">
                            <FaRegEyeSlash />
                          </h4>
                        </Col>
                        <Col className="col-4 text-start">
                          <h4 className="text-white">{item?.point} ຄະເເນນ</h4>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="d-block">
                    {" "}
                    <Row>
                      <h4 className="d-flex justify-content-center m-0 text-white mb-4">
                        ສູນໂຊກໃຫຍ່
                      </h4>
                    </Row>{" "}
                    {userCenterMain?.slice(0, 3).map((item, index) => (
                      <Row
                        key={item?.user_id}
                        className="align-items-center "
                        onClick={() => handleShow(userCenterMain, "ສູນໂຊກໃຫຍ່")}
                      >
                        <Col className="col-3 text-center">
                          <h4 className="text-white">{index + 1}</h4>
                        </Col>
                        <Col className="col-5 text-start">
                          <h4 className="text-white">
                            <FaRegEyeSlash />
                          </h4>
                        </Col>
                        <Col className="col-4 text-start">
                          <h4 className="text-white">{item?.point} ຄະເເນນ</h4>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="d-block">
                    {" "}
                    <Row>
                      <h4 className="d-flex justify-content-center m-0 text-white mb-4">
                        ສູນໂຊກໃຫຍ່ກະເຊົ້າ
                      </h4>
                    </Row>{" "}
                    {userCenterDay?.slice(0, 3).map((item, index) => (
                      <Row
                        key={item?.center_id}
                        className="align-items-center "
                        onClick={() =>
                          handleShow(userCenterDay, "ສູນໂຊກໃຫຍ່ກະເຊົ້າ")
                        }
                      >
                        <Col className="col-3 text-center">
                          <h4 className="text-white">{index + 1}</h4>
                        </Col>
                        <Col className="col-5 text-start">
                          <h4 className="text-white">
                            <FaRegEyeSlash />
                          </h4>
                        </Col>
                        <Col className="col-4 text-start">
                          <h4 className="text-white">{item?.point} ຄະເເນນ</h4>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="d-block">
                    {" "}
                    <Row>
                      <h4 className="d-flex justify-content-center m-0 text-white mb-4">
                        ສູນໂຊກໃຫຍ່ກະແລງ
                      </h4>
                    </Row>{" "}
                    {userCenterNight?.slice(0, 3).map((item, index) => (
                      <Row
                        key={item?.center_id}
                        className="align-items-center "
                        onClick={() =>
                          handleShow(userCenterNight, "ສູນໂຊກໃຫຍ່ກະແລງ")
                        }
                      >
                        <Col className="col-3 text-center">
                          <h4 className="text-white">{index + 1}</h4>
                        </Col>
                        <Col className="col-5 text-start">
                          <h4 className="text-white">
                            <FaRegEyeSlash />
                          </h4>
                        </Col>
                        <Col className="col-4 text-start">
                          <h4 className="text-white">{item?.point} ຄະເເນນ</h4>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="d-block">
                    {" "}
                    <Row>
                      <h4 className="d-flex justify-content-center m-0 text-white mb-4">
                        ສູນຕ່າງແຂວງ
                      </h4>
                    </Row>{" "}
                    {centerAll?.slice(0, 3).map((item, index) => (
                      <Row
                        key={item?.center_id}
                        className="align-items-center "
                        onClick={() => handleShow(centerAll, "ສູນຕ່າງແຂວງ")}
                      >
                        <Col className="col-3 text-center">
                          <h4 className="text-white">{index + 1}</h4>
                        </Col>
                        <Col className="col-5 text-start">
                          <h4 className="text-white">
                            <FaRegEyeSlash />
                          </h4>
                        </Col>
                        <Col className="col-4 text-start">
                          <h4 className="text-white">{item?.point} ຄະເເນນ</h4>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="d-block">
                    {" "}
                    <Row>
                      <h4 className="d-flex justify-content-center m-0 text-white mb-4">
                        ສາຂາທັງຫມົດ
                      </h4>
                    </Row>{" "}
                    {branchAll?.slice(0, 3).map((item, index) => (
                      <Row
                        key={item?.branch_id}
                        className="align-items-center "
                        onClick={() => handleShow(branchAll, "ສາຂາທັງໝົດ")}
                      >
                        <Col className="col-3 text-center">
                          <h4 className="text-white">{index + 1}</h4>
                        </Col>
                        <Col className="col-5 text-start">
                          <h4 className="text-white">
                            <FaRegEyeSlash />
                          </h4>
                        </Col>
                        <Col className="col-4 text-start">
                          <h4 className="text-white">{item?.point} ຄະເເນນ</h4>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControlsNoTouching"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControlsNoTouching"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div> */}
          </Card>
          <div className="mb-2">
            <b style={{ fontSize: "20px" }}>
              <MdDragIndicator size={25} /> ເມນູລັດ
            </b>
            <hr className="my-2" />
          </div>
          <Row>
            <span className="col-3 mb-2 d-flex justify-content-center">
              <Card
                style={{
                  borderRadius: "25px",
                  width: "70px",
                  height: "70px",
                  transition: "transform 0.2s",
                  cursor: "pointer",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                onMouseDown={(event) =>
                  (event.currentTarget.style.transform = "scale(0.95)")
                }
                onMouseUp={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
                onMouseLeave={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
                onClick={() => history.push(`${ABSENCE}`)}
              >
                <div className="text-center align-items-center p-1">
                  <img
                    src={Icon1}
                    alt={"LOGO"}
                    style={{ height: "20px", width: "20px" }}
                  />
                </div>
                <div className="text-center align-items-center">
                  <h6>ໃບລາພັກ</h6>
                </div>
              </Card>
            </span>
            <span className="col-3 d-flex justify-content-center">
              <Card
                style={{
                  borderRadius: "25px",
                  width: "70px",
                  height: "70px",
                  transition: "transform 0.2s",
                  cursor: "pointer",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                onMouseDown={(event) =>
                  (event.currentTarget.style.transform = "scale(0.95)")
                }
                onMouseUp={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
                onMouseLeave={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
                onClick={() => history.push(`${HISTORY_ABSENCE}`)}
              >
                <div className="text-center align-items-center p-1">
                  <img
                    src={Icon2}
                    alt={"LOGO"}
                    style={{ height: "20px", width: "20px" }}
                  />
                </div>
                <div className="text-center align-items-center">
                  <h6>ປະຫວັດການລາພັກ</h6>
                </div>
              </Card>
            </span>
            {/* ຢ່າລືມເອົາ id 21. id 11 ອອກ */}
            {[9, 10, 11, 29, 38, 21].includes(position?._id) && (
              <span className="col-3 d-flex justify-content-center">
                <Card
                  style={{
                    borderRadius: "25px",
                    width: "70px",
                    height: "70px",
                    transition: "transform 0.2s",
                    cursor: "pointer",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                  onMouseDown={(event) =>
                    (event.currentTarget.style.transform = "scale(0.95)")
                  }
                  onMouseUp={(event) =>
                    (event.currentTarget.style.transform = "scale(1)")
                  }
                  onMouseLeave={(event) =>
                    (event.currentTarget.style.transform = "scale(1)")
                  }
                  onClick={() => history.push(`${APPROVE}`)}
                >
                  <div className="text-center align-items-center p-1">
                    <img
                      src={Icon3}
                      alt={"LOGO"}
                      style={{ height: "20px", width: "20px" }}
                    />
                  </div>
                  <div className="text-center align-items-center">
                    <h6>ອະນຸມັດ</h6>
                  </div>
                </Card>
              </span>
            )}
            <span className="col-3 d-flex justify-content-center">
              <Card
                style={{
                  borderRadius: "25px",
                  width: "70px",
                  height: "70px",
                  transition: "transform 0.2s",
                  cursor: "pointer",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                onMouseDown={(event) =>
                  (event.currentTarget.style.transform = "scale(0.95)")
                }
                onMouseUp={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
                onMouseLeave={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
              >
                <div className="text-center align-items-center p-1">
                  <img
                    src={Icon4}
                    alt={"LOGO"}
                    style={{ height: "20px", width: "20px" }}
                  />
                </div>
                <div className="text-center align-items-center">
                  <h6>ເບີກເງີນລວງໜ້າ</h6>
                </div>
              </Card>
            </span>
            {/* <span className="col-3 justify-content-center"> */}
              {/* <Card
                style={{
                  borderRadius: "40px",
                  width: "70px",
                  height: "70px",
                  transition: "transform 0.2s",
                  cursor: "pointer",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onMouseDown={(event) =>
                  (event.currentTarget.style.transform = "scale(0.95)")
                }
                onMouseUp={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
                onMouseLeave={(event) =>
                  (event.currentTarget.style.transform = "scale(1)")
                }
                onClick={() => history.push(`${HOME_VOTE}`)}
              >
                <div className="text-center align-items-center">
                  <img
                    src={IconVote}
                    alt={"LOGO"}
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ position: "absolute", marginTop: "100px" }}
                >
                  ໂຫວດ
                </div>
              </Card> */}
              {/* <span className="d-flex justify-content-center text-danger">ການໂຫລດຄະເເນນໝົດເຂດເເລ້ວ</span> */}
            {/* </span> */}
          </Row>
        </div>
        <div className="text-center">
          <i>DESIGN BY ANOUSITH TECHNOLOGY</i>
        </div>
        <br />
        <BottomNav />
      </div>
    </div>
  );
}
